import React from "react";
import { Route, Switch } from "react-router-dom";

import Prisoversikt from './Prisoversikt'
import Innstillinger from './Innstillinger'
import Ladeplanlegging from './ladeplanlegging'

import Home from './home'

export default function Routes() {
  return (
    <Switch>
        <Route exact path="/"><Home /></Route>
        <Route path="/prisoversikt"><Prisoversikt /></Route>
        <Route path="/innstillinger"><Innstillinger /></Route>
        <Route path="/ladeplanlegging"><Ladeplanlegging /></Route>
        <Route path="/home"> <Home /></Route> 
    </Switch>
  );
}

