import React from "react";
import "./index.css";
import './App.css';
import { Nav, Navbar } from "react-bootstrap";
import { ReactComponent as Logo } from "./logo.svg";
import Routes from "./components/routes";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="#">
          <Logo
            alt=""
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          smarthus
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/#prisoversikt">Prisoversikt</Nav.Link>
            <Nav.Link href="/#innstillinger">Innstillinger</Nav.Link>
            <Nav.Link href="/#ladeplanlegging">Ladeplanlegging</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    <Routes />   
    </div> 
  );
}

export default App;
