import React from 'react'
import { Input, FormGroup, Label, Table, Button, ButtonToggle, Col, Row, InputGroup, InputGroupText  } from 'reactstrap';
import Checkbox from "./checkbox";


import {
  Chart as ChartJS,
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

//import faker from 'faker';

ChartJS.register(
  BarController,
  LineController,  
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);



class Innstillinger extends React.Component {


  
  // Constructor 
  constructor(props) {
    super(props);

    this.settingsIdFromQueryParm = '';


    this.state = {
        id: '',
        DataisLoaded: false,
        zone: 'NO01',
        showGraf1: false,
        valueGraf1: 0,
        showGraf2: false,
        valueGraf2: 0,
        showGraf3: false,
        valueGraf3: 0,
        showGraf4: false,
        valueGraf4: 0,
        showGraf5: false,
        valueGraf5: 0,
        showGraf6: false,
        valueGraf6: 0,
        showGraf7: false,
        valueGraf7: 0,
        showGraf8: false,
        valueGraf8: 0,
    };
  }  

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const _id = queryParams.get("id")
    if(_id !== null) {
      this.settingsIdFromQueryParm = "?id="+_id;
    }
    console.log("settingsIdFromQueryParm="+this.settingsIdFromQueryParm)

    // innebygget xhr, kan erstatte axios ...
    fetch("/api/home/settings"+this.settingsIdFromQueryParm)
        .then((res) => res.json())
        .then((json) => {
            //debugger;
            this.setState({
                DataisLoaded: true,
                id: json.id,
                zone: json.zone,
                showGraf1: json.showGraf1,
                valueGraf1: json.valueGraf1,
                showGraf2: json.showGraf2,
                valueGraf2: json.valueGraf2,
                showGraf3: json.showGraf3,
                valueGraf3: json.valueGraf3,
                showGraf4: json.showGraf4,
                valueGraf4: json.valueGraf4,
                showGraf5: json.showGraf5,
                valueGraf5: json.valueGraf5,
                showGraf6: json.showGraf6,
                valueGraf6: json.valueGraf6,
                showGraf7: json.showGraf7,
                valueGraf7: json.valueGraf7,
                showGraf8: json.showGraf8,
                valueGraf8: json.valueGraf8,
            });

    })

  }  

  
  saveSettings(data) {
    fetch("/api/home/settings", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => {
          console.log(data); // JSON data parsed by `data.json()` call
    })


  }

  handleInputNumber = (e) => {

    const val = e.target.value;
    // If the current value passes the validity test then apply that to state
    if (e.target.validity.valid) this.setState({[e.target.id]: e.target.value});
    // If the current val is just the negation sign, or it's been provided an empty string,
    // then apply that value to state - we still have to validate this input before processing
    // it to some other component or data structure, but it frees up our input the way a user
    // would expect to interact with this component
    else if (val === '' || val === '-') this.setState({[e.target.id]: val});    
  }  

  
  handleInputChanged = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }  

  handleCheckBoxChanged = (e) => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  }  


  // utfører database oppdateringskallet
  lagreSettings() {
    this.saveSettings(this.state);
  }  

  render() {

    const { DataisLoaded } = this.state;
    if (!DataisLoaded) return <div>
        <center>
          <h1>Vennligst vent litt, leser innstillinger ...</h1>
        </center>
         </div> ;

    
    return (

      <div className="App container">
        <div>Innstillinger</div>
        <div>- id: <b>{this.state.id}</b> -</div>
      <div>
        <FormGroup row>
            <Col sm={8}></Col>
                <Col sm={12}>
                  <InputGroup>
                  <Label for="omraade">Prisområde :&nbsp; </Label>
                  <Input type="select" name="select" id="omraade" 
                    value={this.state.zone}
                    onChange={(e) => {

                    let data = this.state;
                    data.zone = e.target.selectedOptions[0].label;
                    console.log("zone: " + data.zone);
                    this.setState({ data });

                    }} >
                       <option>NO1</option>
                       <option>NO2</option>
                       <option>NO3</option>
                       <option>NO4</option>
                       <option>NO5</option>
                   </Input>                  
                  </InputGroup>

                  <Col sm={12}>Bruk positivt tall for å hente ut de dyreste timene i døgnet</Col>
                  <Col sm={12}>Bruk minus for å hente ut de billigste timene i døgnet</Col>


                    <InputGroup>
                      <Label for="valueGraf1">Graf for antall timer :&nbsp; </Label>
                      <Input id="valueGraf1" 
                        value={this.state.valueGraf1} 
                        pattern="^-?[0-9]\d{0,2}$"
                        onChange={this.handleInputNumber} 
                      />
                        &nbsp;
                      <Checkbox id="showGraf1" label='Vis graf' 
                          isSelected={this.state.showGraf1}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf2">Graf for antall timer :&nbsp; </Label>
                      <Input id="valueGraf2" 
                        value={this.state.valueGraf2} 
                        pattern="^-?[0-9]\d{0,2}$"
                        onChange={this.handleInputNumber} 
                      
                      />
                        &nbsp;
                      <Checkbox id="showGraf2" label='Vis graf' 
                          isSelected={this.state.showGraf2}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf3">Graf for antall timer :&nbsp; </Label>
                      <Input id="valueGraf3" 
                        value={this.state.valueGraf3} 
                        pattern="^-?[0-9]\d{0,2}$"
                        onChange={this.handleInputNumber} 
                        />
                        &nbsp;
                        <Checkbox id="showGraf3" label='Vis graf' 
                          isSelected={this.state.showGraf3}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf4">Graf for antall timer :&nbsp; </Label>
                      <Input id="valueGraf4" 
                        value={this.state.valueGraf4} 
                        pattern="^-?[0-9]\d{0,2}$"
                        onChange={this.handleInputNumber} 
                      />
                        &nbsp;
                        <Checkbox id="showGraf4" label='Vis graf' 
                          isSelected={this.state.showGraf4}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>

                </Col>
                <Col sm={12}>Prosentvis over dagens gjennomsnitt</Col>
            <Col sm={12}>Bruk minus for å være under dagens gjennomsnitt</Col>
            <Col sm={12}>
                    <InputGroup>
                      <Label for="valueGraf5">Graf for antall prosent :&nbsp; </Label>
                        <Input id="valueGraf5" 
                          value={this.state.valueGraf5} 
                          pattern="^-?[0-9]\d{0,2}$"
                          onChange={this.handleInputNumber} 
                            />
                        &nbsp;
                        <Checkbox id="showGraf5" label='Vis graf' 
                          isSelected={this.state.showGraf5}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf6">Graf for antall prosent :&nbsp; </Label>
                      <Input id="valueGraf6" 
                          value={this.state.valueGraf6} 
                          pattern="^-?[0-9]\d{0,2}$"
                          onChange={this.handleInputNumber} 
                          />
                        &nbsp;
                        <Checkbox id="showGraf6" label='Vis graf' 
                          isSelected={this.state.showGraf6}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf7">Graf for antall prosent :&nbsp; </Label>
                        <Input id="valueGraf7" 
                          value={this.state.valueGraf7} 
                          pattern="^-?[0-9]\d{0,2}$"
                          onChange={this.handleInputNumber} 
                          />
                        &nbsp;
                        <Checkbox id="showGraf7" label='Vis graf' 
                          isSelected={this.state.showGraf7}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="valueGraf8">Graf for antall prosent :&nbsp; </Label>
                      <Input id="valueGraf8" 
                          value={this.state.valueGraf8} 
                          pattern="^-?[0-9]\d{0,2}$"
                          onChange={this.handleInputNumber} 
                          />
                        &nbsp;
                        <Checkbox id="showGraf8" label='Vis graf' 
                          isSelected={this.state.showGraf8}
                          onCheckboxChange={this.handleCheckBoxChanged}                    
                      />
                    </InputGroup>
                </Col>

        </FormGroup>         
      </div>

      <Button color="primary" onClick={this.lagreSettings.bind(this)}    >Lagre</Button>{' '}
      <p>
      Når du lagrer, vil dine valg legges inn en cookie som lagres i den enheten du nå er inne på
      </p>


  </div>

    )
  }
 }



export default Innstillinger