import React from 'react'

import { WebSocketClient } from './webSocketClient.js'

import {
  Chart as ChartJS,
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

//import faker from 'faker';

ChartJS.register(
  BarController,
  LineController,  
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);



class Prisoversikt extends React.Component {


  
  // Constructor 
  constructor(props) {
    super(props);

    //this.chartReference = React.createRef();   
    
    this.needToReload = false;
    this.dataReloadTime = -1;
    this.settingsIdFromQueryParm = '';

    this.state = {
        data: {},

        //todaysdata : {
          todaysdata:{},
          todaysdatasnitt:{},
          tomorrowsdata:{},
          tomorrowsdatasnitt:{},

        DataisLoaded: false
    };
  }  

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const _id = queryParams.get("id")
    if(_id !== null) {
      this.settingsIdFromQueryParm = "?id="+_id;
    }
    console.log("settingsIdFromQueryParm="+this.settingsIdFromQueryParm)

    this.socketClient = new WebSocketClient('/api/ws', this.onMessage,this.onOpen,this.onClose, this);  

    this.loadData();

  }  

  loadData() {
    
    console.log("loadData .." + this.settingsIdFromQueryParm)


    // innebygget xhr, kan erstatte axios ...
    fetch("/api/home/prices2"+this.settingsIdFromQueryParm)
        .then((res) => res.json())
        .then((json) => {
            //debugger;

            this.needToReload = false;
            var now = new Date();
            this.dataReloadTime = now.getHours();

            // kalkulere tekst, basert på om data er tilstede eller ikke
            let p = json['tomorrows-data'];
            let _tomorrowtekst = "I morgen (dyreste/billigste timene) :"
            if(p.datasets[0].data[0]===0) {
              _tomorrowtekst = "I morgen: Data er ikke klare før ca kl 13.00"
            }
            this.setState({
                todaysdata: json['todays-data'],
                todaysdatasnitt: json['todays-data-snitt'],
                tomorrowsdata: json['tomorrows-data'],
                tomorrowsdatasnitt: json['tomorrows-data-snitt'],
                tomorrowtekst: _tomorrowtekst,
                //todaysdata: o,
                //tomorrowsdata: p,
                DataisLoaded: true
            });
            //debugger;
    })

  }

  onOpen(message) {
    
    var now = new Date();
    console.log("onOpen .. now " + now);

    if(this.needToReload === true) {    
      //console.log("onOpen .. needToReload (true)", this.needToReload);
      console.log("onOpen .. now " + now.getHours()+ " dataReloadTime " + this.dataReloadTime);
      if(this.dataReloadTime !== now.getHours()) {
        console.log("onOpen .. needToReload (loading real data again)", this.dataReloadTime);
        this.loadData();
      } else {
        console.log("onOpen .. needToReload (same time), needToReload -> false", this.dataReloadTime);
        this.needToReload = false;
      }
    } else {
      console.log("onOpen .. needToReload (false)", this.needToReload);
    }

  }

  onClose(message) {
    this.needToReload = true;
    var now = new Date();

    console.log("onClose .. now " + now.getHours()+ " needToReload satt til : ", this.needToReload);
  }

  onMessage(message) {

    console.log("onMessage: " + new Date() + " : " , message);

    if(message === undefined)
      return;

    if(message.data === undefined)
      return;

    let messageData = JSON.parse(message.data);

    if(messageData === undefined || messageData === null )  
      return;

    console.log("reload", messageData.reload)

    if(true === messageData.reload) {
      console.log("reload", messageData.reload)
      this.loadData();
      return;
    }

    //console.log('Message from server ', message.data);
    //debugger;
    let _state = this.state;


    _state.todaysdata.datasets[0].pointRadius = messageData.pointsSize;
    _state.todaysdata.datasets[0].pointBackgroundColor = messageData.pointsColor;

    _state.todaysdatasnitt.datasets[0].pointRadius = messageData.pointsSize;
    _state.todaysdatasnitt.datasets[0].pointBackgroundColor = messageData.pointsColor;

    _state.tomorrowsdata.datasets[0].pointRadius = messageData.pointsSize;
    _state.tomorrowsdata.datasets[0].pointBackgroundColor = messageData.pointsColor;

    _state.tomorrowsdatasnitt.datasets[0].pointRadius = messageData.pointsSize;
    _state.tomorrowsdatasnitt.datasets[0].pointBackgroundColor = messageData.pointsColor;


    this.setState(_state);

  }



  render() {

    const { DataisLoaded } = this.state;
    if (!DataisLoaded) return <div>
        <center>
          <h1>Vennligst vent litt, leser inn priser...</h1>
        </center>
         </div> ;
   

    const options = {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Chart.js Bar Chart - Stacked',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          _beginAtZero: true
        },
      },    

    }


    const optionsNoStack = {
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: 'Chart.js Bar Chart - Stacked',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: false,
        },
      },    

    }



    
    return (
        <div>
          <center>
          Prissone <b>{this.state.todaysdata.zone}</b> <br/>
            I dag (dyreste/billigste timene): 
          </center> 
        <div>
            <Chart type='line' data={this.state.todaysdata} options={options}  height={200} redraw />                
        </div>
        <center>
            I dag (snittpriser): 
          </center> 
        <div>
            <Chart type='line' data={this.state.todaysdatasnitt} options={options}  height={200} redraw />                
        </div>
        <div>
        <hr/>
          <center>
            <b>{this.state.tomorrowtekst}</b>
          </center> 

          <hr/>
          </div>
        <div>
            <Chart type='line' data={this.state.tomorrowsdata} options={options}  height={200} redraw />                
        </div>
        <center>
            I morgen (snittpriser): 
          </center> 
        <div>
            <Chart type='line' data={this.state.tomorrowsdatasnitt} options={options}  height={200} redraw />                
        </div>
            
            
        </div>
    )
  }
 }



export default Prisoversikt