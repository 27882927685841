import React from 'react'
import { Input, FormGroup, Label, Table, Button, ButtonToggle, Col, Row, InputGroup, InputGroupText  } from 'reactstrap';
import Checkbox from "./checkbox";

import {
  Chart as ChartJS,
  BarController,
  LineController,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

//import faker from 'faker';

ChartJS.register(
  BarController,
  LineController,  
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);




class Ladeplanlegging extends React.Component {


  
  // Constructor 
  constructor(props) {
    super(props);

    this.settingsIdFromQueryParm = '';
    this.state = {
        DataisLoaded: false,
        zone: 'NO01',
        kapasitet: '86.5',
    };
  }  

  componentDidMount() {

    const queryParams = new URLSearchParams(window.location.search)
    const _id = queryParams.get("id")
    if(_id !== null) {
      this.settingsIdFromQueryParm = "?id="+_id;
    }
    console.log("settingsIdFromQueryParm="+this.settingsIdFromQueryParm)

    // innebygget xhr, kan erstatte axios ...
    fetch("/api/home/ladeplanlegging"+this.settingsIdFromQueryParm)
        .then((res) => res.json())
        .then((json) => {
            //debugger;
            let p1 = json['data'];
            let p2 = p1['pris'];

            this.setState({
                DataisLoaded: true,
                zone: json.zone,
                kapasitet: json.kapasitet,
                data: p2,
                startSoC : json.startSoC,
                sluttSoC : json.sluttSoC,
                ladefart : json.ladefart,
                startTime : json.startTime,
                sluttTime : json.sluttTime,
                kalkuletStartTid : json.kalkuletStartTid,
                kalkulertSluttTid : json.kalkulertSluttTid,
                ladetid : json.ladetid

            });

    })

  }  

  
  saveOgKalkuler(data) {

    
    let payload = {
      kapasitet: data.kapasitet,
      startTime: data.startTime,
      sluttTime: data.sluttTime,
      startSoC: data.startSoC,
      sluttSoC: data.sluttSoC,
      ladefart: data.ladefart,
      kapasitet: data.kapasitet,
      zone: data.zone
    }
    this.setState({
      DataisLoaded: false
    })

    fetch("/api/home/ladeplanlegging", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then((res) => res.json())
    .then((json) => {

        console.log("p1,p2"); // JSON data parsed by `data.json()` call
        let p1 = json['data'];
        console.log(p1); // JSON data parsed by `data.json()` call
        let p2 = p1['pris'];
        console.log(p2); // JSON data parsed by `data.json()` call

        this.setState({
          DataisLoaded: true,
          zone: json.zone,
          kapasitet: json.kapasitet,
          data: p2,
          startSoC : json.startSoC,
          sluttSoC : json.sluttSoC,
          ladefart : json.ladefart,
          startTime : json.startTime,
          sluttTime : json.sluttTime,
          kalkuletStartTid : json.kalkuletStartTid,
          kalkulertSluttTid : json.kalkulertSluttTid,
          ladetid : json.ladetid

      });


    })


  }


  handleInputNumber = (e) => {

    const val = e.target.value;
    // If the current value passes the validity test then apply that to state
    if (e.target.validity.valid) this.setState({[e.target.id]: e.target.value});
    // If the current val is just the negation sign, or it's been provided an empty string,
    // then apply that value to state - we still have to validate this input before processing
    // it to some other component or data structure, but it frees up our input the way a user
    // would expect to interact with this component
    else if (val === '' || val === '-') this.setState({[e.target.id]: val});    
  }  
  
  handleInputChanged = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    });
  }  

  handleCheckBoxChanged = (e) => {
    this.setState({
      [e.target.id]: e.target.checked
    });
  }  


  // utfører database oppdateringskallet
  kalkuler() {
    this.saveOgKalkuler(this.state);
  }  

  render() {

    const { DataisLoaded } = this.state;
    if (!DataisLoaded) return <div>
        <center>
          <h1>Vennligst vent litt ...</h1>
        </center>
         </div> ;


const options = {
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Chart.js Bar Chart - Stacked',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      _beginAtZero: true
    },
  },    

}

    
    return (

      <div className="App container">
        <div>Ladeplanlegging</div>
      <div>
        <FormGroup row>
            <Col sm={8}></Col>
                <Col sm={12}>
                  <InputGroup>
                  <Label for="omraade">Prisområde :&nbsp; </Label>
                  <Input type="select" name="select" id="omraade" 
                    value={this.state.zone}
                    onChange={(e) => {

                    let data = this.state;
                    data.zone = e.target.selectedOptions[0].label;
                    console.log("zone: " + data.zone);
                    this.setState({ data });

                    }} >
                       <option>NO1</option>
                       <option>NO2</option>
                       <option>NO3</option>
                       <option>NO4</option>
                       <option>NO5</option>
                   </Input>                  
                  </InputGroup>


                    <InputGroup>
                      <Label for="kapasitet">Batteri:&nbsp;</Label>
                      <Input id="kapasitet" 
                        value={this.state.kapasitet} 
                        pattern="^-?[0-9]\d*\.?\d{0,1}$"
                        onChange={this.handleInputNumber} 
                      />
                        &nbsp;
                      <Label for="ladefart">Ladehastighet :&nbsp; </Label>
                      <Input id="ladefart" 
                        pattern="^[0-9]\d*\.?\d{0,1}$"
                        value={this.state.ladefart} 
                        onChange={this.handleInputNumber}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="startSoC">SoC Start:&nbsp;</Label>
                      <Input id="startSoC" 
                        value={this.state.startSoC} 
                        pattern="^[0-9]\d{0,1}$"
                        onChange={this.handleInputNumber}
                      
                      />
                      <Label for="sluttSoC">SoC Slutt:&nbsp; </Label>
                      <Input id="sluttSoC" 
                        value={this.state.sluttSoC} 
                        pattern="^[0-9]\d{0,1}$"
                        onChange={this.handleInputNumber}
                        />
                    </InputGroup>
                    <InputGroup>

                      <Label for="startTime">Ladeperiode:&nbsp; </Label>
                      <Input id="startTime" 
                        value="22.00"
                      />
                        &nbsp;
                   
                      <Label for="sluttTime">til:&nbsp; </Label>
                      <Input id="sluttTime" 
                        value="06:00"
                      />
                    </InputGroup>
                    <InputGroup>
                      <Label for="kalkuletStartTid">Starttid :&nbsp; </Label>
                      <Input id="kalkuletStartTid" 
                        value={this.state.kalkuletStartTid} 
                      />
                        &nbsp;
                        <Label for="kalkulertSluttTid">Sluttid :&nbsp; </Label>
                      <Input id="kalkulertSluttTid" 
                        value={this.state.kalkulertSluttTid} 
                      />

                        </InputGroup>
                    <InputGroup>

                        <Label for="ladetid">Beregnet Ladetid (TT:MM):&nbsp; </Label>
                      <Input id="ladetid" 
                        value={this.state.ladetid} 
                      />

                    </InputGroup>

                </Col>

        </FormGroup>         
      </div>

      <Button color="primary" onClick={this.kalkuler.bind(this)}    >Beregn</Button>{' '}
      <p>
      NB! Mellom Midnatt og kl 13.00 vil ikke Graf, Starttid og Sluttid settes, for man har ikke morgendagens prisen ennå ...
      </p>

      <p>
      Når du beregner, vil dine valg legges inn en cookie som lagres i den enheten du nå er inne på slik at du kommer tilbake til de verdiene du satte sist
      </p>

      <div>
            <Chart type='line' data={this.state.data} options={options}  height={200} />                
        </div>      


  </div>

    )
  }
 }



export default Ladeplanlegging