
// se https://github.com/pladaria/reconnecting-websocket
import ReconnectingWebSocket from 'reconnecting-websocket';

export class WebSocketClient {



    constructor(url, messagehandler, openhandler, closehandler,callingpartner){
        //console.log("webSocketClient.constructor",url, callback, callingpartner);

        //console.log("window.location ",window.location);
        //console.log("window.protocol ",window.location.protocol);

        var url =   (window.location.protocol==='https:' ? 'wss' : 'ws' ) + "://" + window.location.hostname + ":" + window.location.port  + url;

        console.log("websockets url " + url);
    
        this.wsClient = new ReconnectingWebSocket(url);
    
        this.wsClient.addEventListener('message', messagehandler.bind(callingpartner));
        this.wsClient.addEventListener('open', openhandler.bind(callingpartner));
        this.wsClient.addEventListener('close', closehandler.bind(callingpartner));

        // this. wsClient.addEventListener('open', () => {
        //     console.log("websockets open !");
        // });
    }

    disconnect() {
        this.wsClient.close();
        console.log("websockets closed ...!");
    }


}


