import React from 'react';

class Home extends React.Component {

  render() {
    return (
      <div className="container">
      <br/>
      <p>Velkommen til smarthus@bwa.no</p>

      <p>
        Les mer om hva du finner her i <a href="https://wiki.bwa.no/x/ZYBhCw" target="_blank" rel="noreferrer"> dokumentasjonen</a>
      </p>

      <p>

      Velg 'Innstillinger' i menyen ovenfor hvis du vil konfigurere utvalget av grafer til akkurat ditt spesiell behov
      </p>
      <p>
      <b>
      Liker du denne 'appen' og vil ha videre utvikling, vipps gjerne et selvvalgt bidrag til mobilnummber ni-syv-syv-fem-ni-null-null-syv
      </b>
      </p>
      <p>
      Utviklet av Bjørn-Willy Arntzen
      </p>
      <hr/>

      <p>
        <b>Backlog:</b> 
      </p>
      <ul>
        <li>Fargevelger på grafene</li>
        <li>Søtte for Tibber</li>
      </ul>


      <p>
      Versjon: <b>1.4.0</b> - 24.09.2022
      </p>
      <hr/>
      <p>
        Nytt i v1.4.0: 
      </p>
      <ul>
        <li>id for lagret innstilling kan spesifiseres i url. Les mer i <a href="https://wiki.bwa.no/x/ZYBhCw" target="_blank" rel="noreferrer"> dokumentasjonen</a></li>
      </ul>

      <p>
        Nytt i v1.3.0: 
      </p>
      <ul>
        <li>Utnytter webSockets til live refresh</li>
        <li>Markering i tidlinjen med live update</li>
        <li>Priser leses inn ved midnatt og kl 13.00 automatisk uten brukerstyrt reload</li>
      </ul>
      
      <p>
        Nytt i v1.2.3: 
      </p>
      <ul>
        <li>Regnefeil ga en time for sen oppstart i ladeplanlegging</li>
      </ul>

      <p>
        Nytt i v1.2.2: 
      </p>
      <ul>
        <li>Inputkontroll i alle inputfelt</li>
      </ul>

      <p>
        Nytt i v1.2.1: 
      </p>
      <ul>
        <li>Håndterer ladetid over 8 timer</li>
      </ul>

      <p>
        Nytt i v1.2.0: 
      </p>
      <ul>
        <li>Priser inkl mva fra Nordpool</li>
        <li>NO1, NO2, NO3, NO4 og NO5 kan velges i menyen</li>
        <li>Ladeplanlegger, se valg i hovedmeny</li>
      </ul>
      </div>
    )
  }
}
export default Home
